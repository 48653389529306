import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actionSaveShowPopupLogin } from '../../redux/actions/auth';
import useTrans from '@/hooks/useTrans';
import styles from './appdownload.module.css';

const AppDownload = ({ is_home }) => {
    const trans = useTrans();
    const dispatch = useDispatch();
    const setState = show_id => {
        dispatch(actionSaveShowPopupLogin(show_id));
    };
    return (
        <div className={styles["aff_app"]} id='aff_sign_up'>
            <div className={`${styles["aff_app_content"]} ${is_home ? styles.h_content : ''}`}>
            <div className={styles["aff_app_container"]}>
                <div className={styles["aff_app_t"]} data-aos='fade-down'>
                <h2>{trans?.mobile_app?.title}</h2>
                <h3>{trans?.mobile_app?.title_b}</h3>
                <div className='qr_logo'>
                    <div className='pay_a pay_a_aff'>
                    <span style={{ marginBottom: '14px' }}>
                        {' '}
                        <a
                        href='https://play.google.com/store/apps/details?id=com.hana.gold'
                        target='_blank'
                        className='relative'
                        rel='nofollow'
                        >
                        <img
                            src='/assets/img/common/gg_p.webp'
                            alt=''
                            className='lazyload'
                        />
                        <p className='absolute'>Get it on Google Play</p>
                        </a>
                    </span>
                    <span>
                        {' '}
                        <a
                        href='https://apps.apple.com/vn/app/hanagold/id1611748758'
                        target='_blank'
                        className='relative'
                        >
                        <img
                            src='/assets/img/common/app_t.png'
                            alt=''
                            className='lazyload'
                        />
                        <p className='absolute'>Download on the App Store</p>
                        </a>
                    </span>
                    </div>
                    <div className='qr_a qr_a_aff'>
                    <img
                        src='/assets/img/common/hng_qrcode.svg'
                        alt=''
                        className='lazyload'
                    />
                    </div>
                </div>
                <div
                    className='buton_a buton_b'
                    onClick={e => {
                    setState(2);
                    }}
                >
                    <p>{trans?.mobile_app?.btn_res}</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default AppDownload;